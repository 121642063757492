import css from 'styled-jsx/css';

export const styles = css`
    .NavButtons__infoButtons {
      display: flex;
      flex: 0 0 auto;
    }
    .NavButtons__infoButton {
      margin-left: 10px;
      width: 40px;
      height: 40px;
      background-color: var(--colors-transparent);
      border: 1px solid var(--colors-brandLightGrey2);
      border-radius: var(--border-radius-roundBox);
      outline: none;
      font-size: 10px;
      line-height: 1;
      cursor: pointer;
      transition: border-color 0.2s ease-out;
    }
    .NavButtons__infoButton:not([disabled]):hover {
      border-color: var(--uiColors-Button-borderedRoundHoverBorder);
    }
    .NavButtons__infoButton:not([disabled]):active {
      border-color: var(--uiColors-Button-borderedRoundActiveBorder);
    }
    .NavButtons__infoButton[disabled],
    .NavButtons__infoButton.disabled {
      color: var(--colors-brandLightGrey8);
      cursor: default;
    }
  `;
